import React, { useEffect, useState } from "react"
import TextInput from "../components/Inputs/TextInput"
import Button from "components/Buttons/Button"
import { Container, Grid } from "@mui/material"

const PrivateSite = ({ password, children }) => {
  const [value, setValue] = useState("")
  const [error, setError] = useState("")
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false)

  const handleClick = () => {
    if (value !== password) {
      return setError("Hasło jest niepoprawne")
    }
    return setIsPasswordCorrect(true)
  }

  useEffect(() => {
    if (value === "") {
      setError("")
    }
  }, [setError, value])

  if (!!password) {
    return (
      <div>
        {isPasswordCorrect ? (
          <>{children}</>
        ) : (
          <Container>
            <form onSubmit={handleClick}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                py={20}
              >
                <Grid item xs={3}>
                  <TextInput
                    type="password"
                    value={value}
                    placeholder="Hasło"
                    onChange={e => setValue(e.target.value)}
                    helperText={error}
                  />
                </Grid>
                <Grid item>
                  <Button onClick={handleClick}>Wyślij</Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        )}
      </div>
    )
  }
  return <div>{children}</div>
}

export default PrivateSite
